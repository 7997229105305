import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Login extends Component {
    render() {
        return (
            <div className="homeWpr">
                <div className='py-3'>
                    <Link to="/home" className='login-btn'>CONTINUE TO LOGIN</Link>
                </div>
            </div>
        )
    }
}
