import React, { Component } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
export default class Home extends Component {
    constructor() {
        super();
        this.state = {
            list: null,

        }
    }



    getData() {
        fetch("http://localhost:3000/productCodeList").then((response) => {
            response.json().then((result) => {
                this.setState({ list: result })

            })
        })
    }
    oneDataGet() {

    }
    componentDidMount() {
        this.getData()
        // this.getTime();
    }
    render() {

        var now = new Date()
        var date = now.toLocaleDateString();
        var time = now.toLocaleTimeString();
        return (
            <div className='homeWpr'>
                {
                    this.state.list ?
                        <Container className="resultHome">
                            {
                                this.state.list.map((item, i) =>
                                    <div key={i}>
                                        {console.log(date, time)}
                                        {
                                            item.codeFdate === date  && item.codeFTime < time && item.codeSTime > time 
                                             ? <div>
                                                    <Row>
                                                        <Table bordered>
                                                            <tbody>
                                                                {/* <tr>
                                                                    <td colSpan={3}>
                                                                        Live Time: {new Date().toLocaleString() + ''}
                                                                    </td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td>
                                                                        Date:  {item.codeFdate}
                                                                    </td>
                                                                    <td>
                                                                        Frist Time:  {item.codeFTime + item.codeTimeZone}
                                                                    </td>
                                                                    <td>
                                                                        Last Time:  {item.codeSTime + item.codeTimeZone}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                        <Col md={6}>
                                                            <div className="block">
                                                                <h4>Product Code:  <span>{item.codeNoBlock1} </span></h4>
                                                                <div className="img-wpr">
                                                                    <img src={require('../Assets/images/yantra/NV/' + item.codeNoBlock1 + '.jpg').default} alt={'../Assets/images/yantra/NV/' + item.codeNoBlock1 + '.jpg'} height="180px" width="380" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6}>
                                                            <div className="block">
                                                                <h4>Product Code:  <span>{item.codeNoBlock2}</span> </h4>
                                                                <div className="img-wpr">
                                                                    <img src={require('../Assets/images/yantra/RY/' + item.codeNoBlock2 + '.jpg').default} alt={'../Assets/images/yantra/RY/' + item.codeNoBlock1 + '.jpg'} height="180px" width="380" />
                                                                </div>
                                                            </div>
                                                        </Col>

                                                        <Col md={6} >
                                                            <div className="block">
                                                                <h4>Product Code:  <span>{item.codeNoBlock3}</span> </h4>
                                                                <div className="img-wpr">
                                                                    <img src={require('../Assets/images/yantra/RR/' + item.codeNoBlock3 + '.jpg').default} alt={'../Assets/images/yantra/RR/' + item.codeNoBlock1 + '.jpg'} height="180px" width="380" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} >
                                                            <div className="block">
                                                                <h4>Product Code:  <span>{item.codeNoBlock4}</span> </h4>
                                                                <div className="img-wpr">
                                                                    <img src={require('../Assets/images/yantra/CH/' + item.codeNoBlock4 + '.jpg').default} alt={'../Assets/images/yantra/CH/' + item.codeNoBlock1 + '.jpg'} height="180px" width="380" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                : null
                                        }
                                    </div>
                                )
                            }
                        </Container>
                        : <p>Please Wait...</p>
                }
            </div>
        )
    }
}
