import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'

export default class AdminLogin extends Component {

    constructor(){
        super();
        this.state = {
            username : null,
            password : null,
        }
    }

    render() {
        return (
            <div>
                <h1>Admin Login</h1>
                <Row>
                    <Col>
                        <br />
                        <input type="text" placeholder="Username" />
                        <input type="text" placeholder="Password" />
                        <button className="btn-primary">Submit</button>
                    </Col>
                </Row>
            </div>
        )
    }
}
