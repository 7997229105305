import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './components/Home';
import CodeList from './components/Admin/CodeList';
import { BrowserRouter,  Routes,  Route, useParams } from "react-router-dom";
import Dashboard from './components/Admin/Dashboard';
import CodeCreate from './components/Admin/CodeCreate';
import PageNotFound from './components/PageNotFound';
import Login from './components/Login';
import AdminLogin from './components/Admin/AdminLogin';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
                <Route path="/" element={<Login />} />
                <Route path="home" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="createproductcode" element={<CodeCreate />} />
                <Route path="listproductcode" element={<CodeList />} >
                  <Route path=":coadid" element={<CoadId />} />
                </Route>
                <Route path="adminlogin" element={<AdminLogin />} />
                <Route path="*"  element={<PageNotFound /> } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;



function CoadId(){
  const {coadid} = useParams()
  return(
      <div>
          <h2>Use params is : {coadid}</h2>
      </div>
  )
}
