import React, { Component } from 'react';
import { Link } from "react-router-dom";


export default class Nav extends Component {
    render() {
        return (
            <div>
                <nav>
                    <Link to="/CreateProductCode">Create Product Code</Link> | {" "}
                    <Link to="/ListProductCode">List Product Code</Link> | {" "}
                    <Link to="/Home" target="_blank">Go to Live site</Link>
                </nav>
            </div>

        )
    }
}
