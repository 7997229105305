import React, { Component } from 'react'

export default class PageNotFound extends Component {
    render() {
        return (
            <div>
                <h1>Page Not Found</h1>
                <p>We could not find what you were looking for.</p>
                <p>Please contact the owner of the site that linked you to the original URL and let them know their link is broken.</p>
            </div>
        )
    }
}
