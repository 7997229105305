import React, { Component } from 'react'
import Nav from './Nav';

export default class CodeCreate extends Component {
    constructor() {
        super();
        this.state = {
            codeFdate: null,
            codeFTime: null,
            codeSTime: null,
            codeTimeZone: null,
            codeNoBlock1: null,
            codeNoBlock2: null,
            codeNoBlock3: null,
            codeNoBlock4: null,
            errors: {},
        }
    }

    valid(){
        if(this.state.codeFdate === null){
            alert('Please Select Date')
        }
        else if(this.state.codeFTime === null){
            alert('Please Select fTime')
        }
        else if(this.state.codeSTime === null){
            alert('Please Select sTime')
        }
        else if(this.state.codeTimeZone === null){
            alert('Please Select TimeZone')
        }
        else if(this.state.codeNoBlock1 === null){
            alert('NV Code is Required')
        }
        else if(this.state.codeNoBlock2 === null){
            alert('RY Code is Required')
        }
        else if(this.state.codeNoBlock3 === null){
            alert('RR Code is Required')
        }
        else if(this.state.codeNoBlock4 === null){
            alert('CH Code is Required')
        }
    }
 
    createProductCode() {
        fetch('http://localhost:3000/productCodeList', {
            method: "Post",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(this.state)
            }).then((result) => {
                result.json().then((resp) => {
                    console.warn(resp);
                    alert("Product Code Has Been Created!")
                })
            })
        }
    render() {
        return (
            <div>
                <Nav />
                <div>
                    <h3>Code Create</h3>
                    <input type='text' onChange={(e) => { this.setState({ codeFdate: e.target.value }) }} /><br/>
                    Time <br/>From <input type='text'  min="00:00" max="12:00" onChange={(e) => { this.setState({ codeFTime: e.target.value }) }} />
                            To<input type='text' min="00:00" max="12:00" onChange={(e) => { this.setState({ codeSTime: e.target.value }) }} />
                    
                    <select onChange={(e) => { this.setState({ codeTimeZone: e.target.value }) }} >
                        <option>Select</option>
                        <option value="PM">PM</option>
                        <option value="AM">AM</option>
                    </select><br/>
                    {/* <select onChange={(e) => { this.setState({ codeDate: e.target.value }) }} >
                        <option>Select</option>
                        <option value="12/12/2021">12/12/2021</option>
                    </select>
                    <select onChange={(e) => { this.setState({ codeTime: e.target.value }) }} >
                        <option>Select</option>
                        <option value="11:00 AM">11:00 AM</option>
                        <option value="11:15 AM">11:15 AM</option>
                        <option value="11:30 AM">11:30 AM</option>
                    </select>  <br />  */}
                    <input type="text"
                        onChange={(e) => { this.setState({ codeNoBlock1: e.target.value.toUpperCase() }) }} placeholder="Block NV Code" maxLength="4" /> <br />
                    <input type="text"
                        onChange={(e) => { this.setState({ codeNoBlock2: e.target.value }) }} placeholder="Block RY Code" maxLength="4" /> <br />
                    <input type="text"
                        onChange={(e) => { this.setState({ codeNoBlock3: e.target.value }) }} placeholder="Block RR Code" maxLength="4" /> <br />
                    <input type="text"
                        onChange={(e) => { this.setState({ codeNoBlock4: e.target.value }) }} placeholder="Block CH Code" maxLength="4" /> <br />
                    <button className="btn-primary" onClick={() => { this.createProductCode() }}>Create</button>
                </div>
            </div>
            
        )
    }
}
