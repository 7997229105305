import React, { Component } from 'react'
import { Table } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import Nav from './Nav';

export default class CodeList extends Component {
    constructor() {
        super();
        this.state = {
            list: null,

        }
    }
    
    getData(){
        fetch("http://localhost:3000/productCodeList").then((response) => {
            response.json().then((result) => {
                this.setState({ list: result })
                console.warn(result);
            })
        })
    }
    

    componentDidMount() {
        this.getData()
    }
    render() {
        return (
            <div>
                <Nav />
                <h3>Code List </h3>
                {
                    this.state.list ?
                        <div className='codeListGrid'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                    {/* <th>Database Date Time</th>
                                        <th>Date</th>
                                        <th>Time</th> */}

                                        <th>F Date</th>
                                        <th>Frist Time</th>
                                        <th>Second Time</th>

                                        <th>Code Block 1</th>
                                        <th>Code Block 2</th>
                                        <th>Code Block 3</th>
                                        <th>Code Block 4</th>
                                        <th>Action #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                this.state.list.map((item, i) =>
                                    <tr key={item.id} className="list-resturant">
                                        {/* <td >{item.codeDateTime}</td>
                                        <td >{item.codeDate}</td>
                                        <td>{item.codeTime}</td> */}

                                        <td>{item.codeFdate}</td>
                                        <td>{item.codeFTime}</td>
                                        <td>{item.codeSTime}</td>


                                        <td>{item.codeNoBlock1}</td>
                                        <td>{item.codeNoBlock2}</td>
                                        <td>{item.codeNoBlock3}</td>
                                        <td>{item.codeNoBlock4}</td>
                                        <td><Link to={'/nav/codedetails' +item.id}>Delete</Link> </td>
                                    </tr>
                                )
                            }
                                </tbody>
                            </Table>
                            <Outlet />
                        </div>
                        : <p>Please Wait...</p>
                }
            </div>
        )
    }
}
